import { Route, Routes } from 'react-router-dom';

import Pages from './config/pages';
import { NotFound } from './views/NotFound';

function App() {
  return (
    <Routes>
        {
          Pages.map((page, k) => (
            <Route key={k} path={page.path} element={page.element} />
          ))
        }
        <Route element={<NotFound/>} />
      </Routes>
  );
}

export default App;