import { Welcome } from "../views/welcome";
import { NotFound } from "../views/NotFound";
import { Redirecter } from "../components/utils/Redirecter";

const Pages = [
    {
        "path": "",
        "title": "",
        "navbar": false,
        "element": <Redirecter link={"/home"}/>
    },
    {
        "path": "home",
        "title": "Welcome",
        "navbar": true,
        "element": <Welcome />
    },
    {
        "path": "404",
        "title": "404 Not found",
        "navbar": false,
        "element": <NotFound />
    },
]

export default Pages;