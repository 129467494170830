export interface Delicacy {
    id: number;
    name: string;
    description: string;
    image: string;
}

export interface Category {
    id: number;
    name: string;
}

interface DelicaciesStorage {
    [key: number]: Delicacy[];
}

export class Delicacies {

    public static categories: Category[] = [
        {id: 0, name: "Tartes"},
        {id: 1, name: "Gâteaux"},
        {id: 2, name: "Bûches"},
        {id: 3, name: "Entremets"},
        {id: 4, name: "Petites douceurs"}
    ];
    public static delicacies: DelicaciesStorage = {
        0: [
            {
                id: 0,
                description: "Tarte aux poires et crème d'amande",
                image: "5GhfD9Xn8o.jpg",
                name: "Tarte bourdaloue"
            },
            {
                id: 1,
                description: "",
                image: "1719995209165.jpg",
                name: "Tarte crumble et rhubarbe"
            },
            {
                id: 2,
                description: "Crème pistache et coulis de fraise",
                image: "fwCE5HOJlY.jpg",
                name: "Tarte aux fraises"
            },
            {
                id: 3,
                description: "Sablé breton avec son coulis de figues et sa crème pâtissière",
                image: "oZDqaufRt1.jpg",
                name: "Tarte aux figues"
            },
            {
                id: 4,
                description: "A la crème d'amande",
                image: "p9R5NRrCJM.jpg",
                name: "Tarte aux pommes"
            },
            {
                id: 5,
                description: "Avec son croustillant giantduja",
                image: "SKG0GrL9PZ.jpg",
                name: "Tarte passion dulcey"
            },
            {
                id: 6,
                description: "",
                image: "uea12tYDDA.jpg",
                name: "Tarte citron meringuée"
            },
            {
                id: 7,
                description: "Chantilly mascarpone",
                image: "VRygcyZQt0.jpg",
                name: "Tarte aux framboises"
            }
        ],
        1: [
            {
                id: 0,
                description: "Moelleux au chocolat noir",
                image: "5NpD6JS5cH.jpg",
                name: "Moelleux au chocolat"
            },
            {
                id: 1,
                description: "Flan au chocolat noir et pâte cookie",
                image: "1719995209134.jpg",
                name: "Flan parisien"
            },
            {
                id: 2,
                description: "Vanille et chocolat",
                image: "AUsr5xno9e.jpg",
                name: "Gâteau marbré"
            },
            {
                id: 3,
                description: "Spéculoos et framboises",
                image: "hiTuUinclT.jpg",
                name: "Cheesecake"
            },
            {
                id: 4,
                description: "Avec de la mascarpone",
                image: "kEvUpKSHHV.jpg",
                name: "Gâteau aux pommes"
            },
            {
                id: 5,
                description: "",
                image: "rfSssws8OU.jpg",
                name: "Gâteau au citron"
            },
            {
                id: 6,
                description: "Aux pommes",
                image: "L9rjbzAVH6.jpg",
                name: "Gâteau à la normande"
            }
        ],
        2: [
            {
                id: 0,
                description: "Mousse chocolat dulcey et biscuit spéculoos",
                image: "9oHM2OKWl2.jpg",
                name: "Bûche fruits de la passion"
            },
            {
                id: 1,
                description: "Mousse au chocolat noir et moelleux au chocolat",
                image: "EObSFCpLMt.jpg",
                name: "Bûche cassis/myrtille"
            },
            {
                id: 2,
                description: "Mousse chocolat 66% et sablé breton",
                image: "HaLZDcI6zm.jpg",
                name: "Bûche poires"
            },
            {
                id: 3,
                description: "Biscuit et mousse au chocolat noir",
                image: "tROxh5Jh5O.jpg",
                name: "Bûche framboises"
            }
        ],
        3: [
            {
                id: 0,
                description: "",
                image: "2UbxcsUxc2.jpg",
                name: "Entremet cookie chocolat"
            },
            {
                id: 1,
                description: "Brownie avec sa crème pâtissière pralinée et son croustillant",
                image: "16qNYwG7x2.jpg",
                name: "Jaubourg"
            },
            {
                id: 2,
                description: "Mousse aux 3 chocolats et son coulis de fruits rouges",
                image: "1719995209146.jpg",
                name: "3 chocolats fruits rouges"
            },
            {
                id: 3,
                description: "Moelleux au chocolat et son croustillant praliné",
                image: "q36SwTVdyZ.jpg",
                name: "Entremet 3 chocolats"
            }
        ],
        4: [
            {
                id: 0,
                description: "Eclaires à la crème pâtissière",
                image: "ExKQmQ8qOf.jpg",
                name: "Eclaires"
            },
            {
                id: 1,
                description: "Pâte au crumble",
                image: "1719995241501.jpg",
                name: "Carrés fraise rhubarbe"
            },
            {
                id: 2,
                description: "Aux pépites instense en chocolat",
                image: "fxS3wemfvv.jpg",
                name: "Petit moelleux au chocolat"
            },
            {
                id: 3,
                description: "Vanille, chocolat et praliné",
                image: "gQ7TAXhSQI.jpg",
                name: "Petits choux"
            },
            {
                id: 4,
                description: "Au chocolat noir",
                image: "nLK6CcKrZ8.jpg",
                name: "Mousse au chocolat"
            },
            {
                id: 5,
                description: "Chocolat au choix",
                image: "p8Emrzm3GQ.jpg",
                name: "Cookies"
            },
            {
                id: 6,
                description: "Gâteau fondant et sa ganache au chocolat au lait, noix caramélisées",
                image: "1720013493442.jpg",
                name: "Fingers au chocolat"
            }
        ]
    }
}
