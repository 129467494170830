import React, { useState } from 'react';

import style from "./styles/imageloader.module.css";

interface imageLoaderProps {
    src:string;
    alt:string;
    className?:string;
}

const ImageLoader:React.FC<imageLoaderProps> = ({ src, alt, className }) => {
  const [loading, setLoading] = useState(true);

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className={`${style.imageLoaderContainer} ${className}`}>
      {loading && <div className={style.loader}></div>}
      <img className={style.image}
        src={src}
        alt={alt}
        style={{ display: loading ? 'none' : 'block' }}
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default ImageLoader;