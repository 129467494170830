import { Navigate } from "react-router-dom";

interface RedirecterProps {
    link: string;
}

export const Redirecter:React.FC<RedirecterProps> = ({link}) => {
    return (
        <Navigate to={link} />
    );
}