import { useEffect, useRef, useState } from "react";
import style from "./styles/welcome.module.css";
import { Delicacies, Delicacy } from "../scripts/Delicacy";
import ImageLoader from "../components/utils/ImageLoader";

export const Welcome = () => {
  const categoryContentRef = useRef<HTMLDivElement | null>(null);
  const [delicacies, setDelicacies] = useState<Delicacy[]>([]);
  
  const updateDelicacies = (category: number) => {
    setDelicacies(Delicacies.delicacies[category]);
  };

  const selectCategoryHandler = (category: number) => {
    updateDelicacies(category);

    categoryContentRef.current?.scrollIntoView({
      behavior: "smooth",
      block: window.innerWidth <= 768 ? "start" : "center",
    });
  };

  useEffect(() => {
    updateDelicacies(0);
  }, []);

  return (
    <>
      <div className={style.header}>
        <img src="/images/logo.svg" alt="logo" className={style.logo} />
        <div className={style.titles}>
          <h1>Gourmandise Laurence</h1>
          <p>
            Passionnée par le monde de la pâtisserie, je consacre mon temps
            libre à la réalisation de jolies gourmandises.
          </p>
        </div>
      </div>
      <div className={style.categoryContainer}>
        {Delicacies.categories.map((category, k) => (
          <div
            key={"category-" + k}
            className={style.category}
            onClick={() => selectCategoryHandler(category.id)}
          >
            <h1>{category.name}</h1>
          </div>
        ))}
      </div>
      <div className={style.categoryContent} ref={categoryContentRef}>
        {delicacies?.map((delicacy, k) => (
          <div key={"delicacy-" + k} className={style.delicacy}>
            <h1>{delicacy.name}</h1>
            <ImageLoader
              src={"/images/" + delicacy.image}
              alt=""
              className={style.image}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: delicacy.description.replaceAll("\n", "<br>"),
              }}
            ></p>
          </div>
        ))}
      </div>
    </>
  );
};
